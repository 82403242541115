/* istanbul ignore file */
import React from 'react';
import { Box } from '@mui/material';
import { CssTextField } from 'components/select';
import Autocomplete from '@mui/material/Autocomplete';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import throttle from 'lodash/throttle';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import SearchIcon from '@mui/icons-material/Search';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import AddMember from 'pages/project-onboarding/components/Form/ServiceMembers/AddMember';

const SCAMember = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [value, setValue] = React.useState(null);
    const [ccInputValue, setCCInputValue] = React.useState('-');
    const [loading, setLoading] = React.useState(false);
    const [options, setOptions] = React.useState([]);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [searched, setSearched] = React.useState(false);

    const memberApi = useMutation(() => axios.get(`${endPoints.day2Operation?.ProjectOnboarding?.member}?key=${ccInputValue}`));
    const memberPostApi = useMutation((payload) => axios.get(`${endPoints.day2Operation.ProjectOnboarding.member}?key=${payload}`));

    const setFortifySnykMember = (scaMember, snykMemberDetails) => {
        setOrder({
            ...order,
            scaMember,
            snykMemberDetails,
        });
    };

    React.useEffect(() => {
        if (!order?.scaMember && !order?.snykMemberDetails) {
            setOptions([]);
        }
    }, [order?.scaMember, order?.snykMemberDetails]);

    const resetOptions = () => {
        setOptions([]);
        setFortifySnykMember(null, null);
    };

    const fetch = React.useMemo(
        () =>
            throttle(() => {
                memberApi.mutate();
            }, 1000),
        []
    );
    const handleSearch = () => {
        // istanbul ignore if
        if (ccInputValue === '') {
            resetOptions();
        }
        // istanbul ignore else
        if (ccInputValue !== '' && ccInputValue.length > 2) {
            setSearched(true);
            fetch();
        }
    };

    const isExistsInOptions = (appOwnerValue) => options.find((option) => appOwnerValue === option.name);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.scaMember && order?.snykMemberDetails) {
            setOrder({ ...order, scaMember: options[0]?.label, snykMemberDetails: options?.[0] });
        }
        if (options && options?.length > 1 && order?.snykMemberDetails && !order?.scaMember) {
            const appOwerObj = isExistsInOptions(order?.snykMemberDetails?.name);
            setOrder({ ...order, scaMember: options[0]?.label, snykMemberDetails: appOwerObj });
        }
    }, [options]);

    React.useEffect(() => {
        if (memberApi?.data?.data) {
            const combinedValue = memberApi?.data?.data?.map((app) => ({
                label: `${app?.gpid} - ${app?.name}`,
                gpid: `${app?.gpid}`,
                name: `${app?.name}`,
                email: app?.email,
                role: order?.scaRoles,
            }));
            setOptions(combinedValue);
        }
    }, [memberApi?.data?.data]);

    React.useEffect(() => {
        if (memberPostApi?.data?.data) {
            const combinedValue = memberPostApi?.data?.data?.map((app) => ({
                label: `${app?.gpid} - ${app?.name}`,
                gpid: `${app?.gpid}`,
                name: `${app?.name}`,
                email: app?.email,
            }));
            setOptions(combinedValue);
        }
    }, [memberPostApi?.data?.data]);

    React.useEffect(() => {
        if (value === null) {
            // resetOptions();
        } else {
            setOptions(options?.filter((z) => z?.gpid === value?.gpid));
            setFortifySnykMember(value?.label, value);
        }
    }, [value]);

    React.useEffect(() => {
        if (order?.scaMember && !order?.snykMemberDetails) {
            memberPostApi.mutate(order?.scaMember);
        }
    }, [order?.scaMember, order?.snykMemberDetails]);

    const validateMember = (member) => {
        const duplicate = order?.snykMembers?.filter((item) => item?.gpid === member?.gpid && item?.role === order?.scaRoles);
        if (duplicate && duplicate?.length) {
            setValidForm({ ...isValidForm, isDuplicateSCAMember: true });
        } else {
            setValidForm({ ...isValidForm, isDuplicateSCAMember: false });
        }
    };

    const handleAddMember = async () => {
        if (order?.snykMembers?.length > 14) {
            setValidForm({ ...isValidForm, isSCAMaxMemberLimit: true });
        } else {
            setLoading(!loading);
            try {
                const member = {
                    orgId: order?.teamDetails?.id,
                    role: order?.snykMemberDetails?.role?.toLowerCase(),
                    emailId: order?.snykMemberDetails?.email,
                };

                const result = await axios.post(endPoints.day2Operation?.ProjectOnboarding?.Jwt?.scaMemberValidate, member);

                if (!isValidForm?.isDuplicateSCAMember) {
                    if (!result.data && result?.isSuccess) {
                        setValidForm({ ...isValidForm, isSCAMemberOrganizationInvalid: false });
                        // eslint-disable-next-line

                        setOrder({
                            ...order,
                            snykMembers: [...order?.snykMembers, order?.snykMemberDetails],
                            scaMember: null,
                            snykMemberDetails: null,
                        });

                        setLoading(false);
                    } else {
                        setValidForm({ ...isValidForm, isSCAMemberOrganizationInvalid: true });
                    }
                }

                setLoading(false);
            } catch (e) {
                setLoading(false);
                // eslint-disable-next-line
                console.log('Exception', e);
            }
        }
    };

    const getHelperText = () => {
        let errorText;
        if (isValidForm?.isDuplicateSCAMember) {
            errorText = 'Member already added in the list.';
        } else if (isValidForm?.isSCAMaxMemberLimit) {
            errorText = 'You have reached max limit of 15 members.';
        } else if (isValidForm?.isSCAMemberOrganizationInvalid) {
            errorText = 'Member exists in snyk organization.';
        }
        return errorText;
    };

    const getNoOptionText = () => {
        let text = '';
        if (searched === false) {
            text = 'Type & press enter to search by GPID, Firstname and Lastname';
        } else if (options?.length === 0) {
            text = 'No results found';
        }
        return text;
    };

    React.useEffect(() => {
        const uniqueMembers = order?.snykMembers?.reduce((acc, member) => {
            const existingMember = acc?.find((item) => item?.gpid === member?.gpid);
            if (!existingMember) {
                acc?.push(member);
            }
            return acc;
        }, []);

        setOrder({
            ...order,
            snykPreviewMembers:
                uniqueMembers?.map((member) => {
                    const duplicate = order?.snykMembers?.filter((item) => item?.gpid === member?.gpid);
                    if (duplicate?.length > 1) {
                        return {
                            gpid: member?.gpid,
                            name: member?.name,
                            email: member?.email,
                            role: duplicate?.map((item) => item?.role)?.join(', '),
                        };
                    }
                    return member;
                }) || [],
        });
    }, [order?.snykMembers?.length]);

    return (
        <>
            <Box display="flex" alignItems="flex-start" gap={1}>
                <Autocomplete
                    data-testid="security-tool-autocomplete"
                    key="member"
                    size="small"
                    noOptionsText={getNoOptionText()}
                    includeInputInList
                    loading={memberApi?.isLoading}
                    value={order?.scaMember || null}
                    onInputChange={(event, newInputValue) => {
                        if (newInputValue) setCCInputValue(newInputValue?.includes('-') ? newInputValue?.split('-')?.pop()?.trim() : newInputValue);
                    }}
                    onChange={(event, newValue) => {
                        // istanbul ignore else
                        if (typeof newValue === 'string') {
                            setValue({
                                label: newValue,
                            });
                        } else if (newValue && newValue.inputValue) {
                            // Create a new value from the user input
                            setValue({
                                label: newValue.inputValue,
                            });
                        } else {
                            setValue(newValue);
                            validateMember(newValue);
                        }
                    }}
                    isOptionEqualToValue={(option, value1) => option.id === value1.id}
                    renderOption={(props, option) => (
                        <li {...props} key={option?.gpid}>
                            {option?.label}
                        </li>
                    )}
                    onBlur={() => {
                        if (!value || !order?.scaMember) {
                            setSearched(false);
                            setOptions([]);
                        }
                    }}
                    onKeyUp={(event) => {
                        if (event.key === 'Enter') {
                            setSearched(true);
                            handleSearch();
                        }
                        if ((event.key === 'Backspace' || event.key === 'Delete') && (!event.target.value || event.target.value === '')) {
                            setSearched(false);
                            resetOptions();
                            setValidForm({ ...isValidForm, isDuplicateSCAMember: false, isSCAMaxMemberLimit: false });
                        }
                    }}
                    filterOptions={(optionValues, params) => {
                        // eslint-disable-next-line no-underscore-dangle
                        const _results = [];
                        // eslint-disable-next-line no-plusplus
                        for (let i = 0; i < optionValues.length; i++) {
                            // eslint-disable-next-line no-restricted-syntax
                            for (const key in optionValues[i]) {
                                if (optionValues[i][key] !== null && typeof optionValues[i][key] !== 'boolean' && typeof optionValues[i][key] !== 'number') {
                                    if (optionValues[i][key].toLowerCase().includes(params?.inputValue.toLowerCase())) {
                                        _results.push(optionValues[i]);
                                    }
                                }
                            }
                        }
                        return [...new Set(_results)];
                        // return _results
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    disableClearable
                    id="free-solo-with-text-demo"
                    options={options}
                    disabled={!order?.scaRoles}
                    sx={{ width: '80%' }}
                    renderInput={(params) => (
                        <CssTextField
                            size="large"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            label="Add Members for SCA *"
                            helperText={<span style={{ color: '#FF3939' }}>{getHelperText()}</span>}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>
                                        <SearchIcon
                                            sx={ProjectOnboardingStyles.MemberAction.searchIcon}
                                            onClick={() => {
                                                setSearched(true);
                                                handleSearch();
                                            }}
                                            data-testid="search-btn"
                                        />
                                    </>
                                ),
                            }}
                            className={order?.isSCAMaxMemberLimit?.length === 0 && order?.isTouched ? 'validate' : ''}
                            fullWidth
                        />
                    )}
                />
                <AddMember handleAddMember={() => handleAddMember()} isLoading={loading} />
            </Box>
        </>
    );
};

export default React.memo(SCAMember);
