import React from 'react';
import { Box, CircularProgress } from '@mui/material';
import { CssTextField } from 'components/select';
import DoneIcon from '@mui/icons-material/Done';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import { OrderPayload, validOnboardingForm, isValidationLoading } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';

export const SASTApplicationName = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [, setIsLoading] = useRecoilState(isValidationLoading);
    const [helperText, setHelperText] = React.useState(false);
    const [errorText, setErrorText] = React.useState('');

    const validateApplicationName = useMutation(() =>
        axios.get(`${endPoints.day2Operation.ProjectOnboarding?.Jwt?.sastApplicationNameValidate}?projectName=${order?.sastApplicationName}`)
    );

    const setServiceAccount = (sastApplicationName = null) => {
        setOrder({
            ...order,
            sastApplicationName,
        });
    };

    const hasWhiteSpace = (value) => {
        return value?.indexOf(' ') >= 0;
    };
    React.useEffect(() => {
        if (
            order?.sastApplicationName &&
            order?.sastApplicationName !== null &&
            order?.sastApplicationName !== '' &&
            order?.sastApplicationName !== undefined &&
            (order?.sastApplicationName?.length < 4 || order?.sastApplicationName?.length > 64)
        ) {
            setHelperText(true);
            setErrorText('Application SAST Name must have min 4 chars');
            setValidForm({ ...validForm, isAppNameValid: true });
        } else {
            if (hasWhiteSpace(order?.sastApplicationName)) {
                setHelperText(true);
                setErrorText('Space are not allowed in sast application short.');
                return setValidForm({ ...validForm, isAppNameValid: true });
            }
            setHelperText(false);
            setValidForm({ ...validForm, isAppNameValid: false });
        }
        return null;
    }, [order?.sastApplicationName]);

    const validateInput = () => {
        if (
            (isTouched && order?.isTouched && !order?.sastApplicationName) ||
            helperText ||
            (validForm?.isInvalidSASTApplicationName &&
                validForm?.isInvalidSASTApplicationName &&
                (validateApplicationName?.data?.messages?.length > 0 || validateApplicationName?.error?.length > 0))
        ) {
            return 'validate';
        }
        return '';
    };

    const getHelperText = () => {
        if (helperText) {
            return errorText;
        }
        if (
            validForm?.isInvalidSASTApplicationName &&
            validForm?.isInvalidSASTApplicationName &&
            (validateApplicationName?.data?.messages?.length > 0 || validateApplicationName?.error?.length > 0)
        ) {
            return <Box sx={ProjectOnboardingStyles.error}>{validateApplicationName?.data?.messages?.[0] || validateApplicationName?.error?.[0]}</Box>;
        }
        if (validateApplicationName?.isLoading) {
            return 'Checking SAST application name availability';
        }
        return '';
    };

    React.useEffect(() => {
        if (validateApplicationName?.isSuccess && validateApplicationName?.data?.data) {
            setValidForm({ ...validForm, isInvalidSASTApplicationName: true });
        }
        if (validateApplicationName?.isSuccess && !validateApplicationName?.data?.data) {
            setValidForm({ ...validForm, isInvalidSASTApplicationName: false });
        }
    }, [validateApplicationName?.isSuccess]);

    const debounceTimeoutRef = React.useRef(null);

    React.useEffect(() => {
        clearTimeout(debounceTimeoutRef.current);
        debounceTimeoutRef.current = setTimeout(() => {
            if (order?.sastApplicationName && order?.sastApplicationName?.trim()?.length > 3) {
                validateApplicationName.mutate();

                setIsLoading(validateApplicationName?.isLoading);
            }
        }, 500);

        return () => {
            clearTimeout(debounceTimeoutRef.current);
        };
    }, [order?.sastApplicationName]);

    const getAppNameValid = () => {
        if (validateApplicationName?.isLoading) {
            return <CircularProgress color="inherit" size={14} />;
        }
        if (order?.sastApplicationName && !validForm?.isInvalidSASTApplicationName && !validForm?.isAppNameValid) {
            return !validateApplicationName?.isLoading && validateApplicationName?.isSuccess ? <DoneIcon sx={{ color: '#00A86B' }} /> : null;
        }

        return false;
    };

    React.useEffect(() => {
        setIsLoading(validateApplicationName?.isLoading);
    }, [validateApplicationName?.isLoading]);

    React.useEffect(() => {
        if (!order?.sastApplicationName && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.sastApplicationName, order?.isTouched]);

    return (
        <Box display="flex" alignItems="flex-start" flexDirection="column" data-testid="service-account-text" minHeight={62} marginBottom={0}>
            <CssTextField
                data-testid="app-name-text"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                disabled={!order?.adoPlatform}
                autoComplete="off"
                label="SAST Application Name *"
                isLoading={validateApplicationName?.isLoading}
                error={helperText}
                helperText={getHelperText()}
                value={order?.sastApplicationName}
                onChange={(e) => setServiceAccount(e.target.value)}
                className={validateInput()}
                InputProps={{
                    endAdornment: <>{getAppNameValid()}</>,
                }}
            />
        </Box>
    );
};

export default React.memo(SASTApplicationName);
