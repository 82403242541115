import React from 'react';
import { Box, Divider, Grid } from '@mui/material';
import Style from 'pages/project-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

const ApplicationDetails = () => {
    const [order] = useRecoilState(OrderPayload);
    const LabelValue = (label, value) => (
        <Grid item xs={6} sm={6}>
            <Box sx={Style?.preview?.projectDetails?.subWrapper}>
                <Box sx={Style?.preview?.projectDetails?.label}>{label}:</Box>
                <Box sx={Style?.preview?.projectDetails?.value}>{value || '-'}</Box>
            </Box>
        </Grid>
    );
    return (
        <Box sx={Style?.preview?.wrapper}>
            <Box sx={Style?.preview?.heading}>Application Details</Box>
            <Divider variant="horizontal" sx={Style?.preview?.divder} />
            <Grid container sx={Style?.preview?.projectDetails?.container} spacing={2}>
                {order?.applicationName && <>{LabelValue('Application Name', order?.applicationName)}</>}
                {order?.appShortName && order?.isServiceAccountExists === 'no' && <>{LabelValue('Application Short Name', order?.appShortName)}</>}
                {LabelValue('Business Application', order?.businessApplication)}
                {LabelValue('Application Service', order?.applicationService)}
                {LabelValue('Application Owner', order?.applicationOwner)}
            </Grid>
        </Box>
    );
};

export default ApplicationDetails;
