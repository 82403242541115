/* eslint-disable import/no-named-default */

import { default as AvailableADOProject } from './AvailableADOProject';
import { default as AvailableClarityId } from './AvailableClarityId';
import { default as ClarityId } from './ClarityId';
import { default as ADOProjectName } from './ADOProjectName';
import { default as NewADOProjectName } from './NewADOProjectName';
import { default as Sector } from './Sector';
import { default as ADOPlatform } from './ADOPlatform';
import { default as ADOProjectType } from './ADOProjectType';
import { default as ApplicationCI } from './ApplicationCI';
import { default as ApplicationName } from './ApplicationName';
import { default as AppShortName } from './AppShortName';
import { default as BusinessApplication } from './BusinessApplication';
import { default as ApplicationService } from './ApplicationService';
import { default as ApplicationOwner } from './ApplicationOwner';
import { default as ProjectAdministrator } from './ProjectAdministrator';
import { default as ServiceResources } from './ServiceResources';
import { default as AvailableServiceAccountQuestion } from './AvailableServiceAccountQuestion';
import { default as ServiceAccount } from './ServiceAccount';
import { default as OnboardQuestion } from './OnboardQuestion';
import { default as FortySnykOnboardQuestion } from './FortySnykOnboardQuestion';
import { default as SASTOptions } from './SASTOptions';
import { default as SASTMember } from './ServiceMembers/SASTMember';
import { default as SASTApplicationNameSelect } from './SASTApplicationNameSelect';
import { default as SASTVersionSelect } from './SASTVersionSelect';
import { default as SCARoles } from './SCARoles';
import { default as SASTApplicationName } from './SASTApplicationName';
import { default as SASTVersion } from './SASTVersion';
import { default as SCAMember } from './ServiceMembers/SCAMember';
import { default as NewProjectOnboardQuestion } from './NewProjectOnboardQuestion';

const Forms = {
    AvailableADOProject,
    AvailableClarityId,
    ClarityId,
    ADOProjectName,
    NewADOProjectName,
    Sector,
    ADOPlatform,
    ADOProjectType,
    ApplicationCI,
    ApplicationName,
    AppShortName,
    BusinessApplication,
    ApplicationService,
    ApplicationOwner,
    ProjectAdministrator,
    ServiceResources,
    AvailableServiceAccountQuestion,
    ServiceAccount,
    OnboardQuestion,
    FortySnykOnboardQuestion,
    SASTOptions,
    SASTMember,
    SASTApplicationNameSelect,
    SASTVersionSelect,
    SCARoles,
    SASTApplicationName,
    SASTVersion,
    SCAMember,
    NewProjectOnboardQuestion,
};

export default Forms;
