import React from 'react';
import { Box, Checkbox, FormControlLabel, InputLabel, Grid, Typography } from '@mui/material';
import { useRecoilState } from 'recoil';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import InfoTooltip from 'pages/project-onboarding/components/info-tooltip';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import ServiceDialog from 'pages/project-onboarding/components/Form/ServiceConfiguration/ServiceDialog';

const FortySnykOnboardQuestion = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [isOpen, setIsOpen] = React.useState(false);
    const [resetForm, setResetForm] = React.useState(null);

    const setSAST = () => {
        setOrder({
            ...order,
            onboardSAST: !order?.onboardSAST,
            sastApplicationName: null,
            sastVersion: null,
            applicationNameWithVersions: [],
            fortifyOption: null,
            fortifyMembers: [],
            sastMember: null,
            sastMemberDetails: null,
        });
        setValidForm({ ...isValidForm, isDuplicateSASTMember: false });
    };

    const setSCA = () => {
        setOrder({
            ...order,
            onboardSCA: !order?.onboardSCA,
            teamName: null,
            scaRoles: null,
            snykMemberDetails: null,
            scaMember: null,
            snykMembers: [],
        });
        setValidForm({ ...isValidForm, isDuplicateSCAMember: false, isSCAMemberOrganizationInvalid: false });
    };

    const handleChange = (e, type) => {
        if (type === 'sast') {
            if (order?.onboardSAST) {
                setResetForm({ type: 'sast', title: 'Are you sure you want to reset the SAST Configuration' });
                setIsOpen(true);
            } else {
                setSAST();
            }
        }
        if (type === 'sca') {
            if (order?.onboardSCA) {
                setIsOpen(true);
                setResetForm({ type: 'sca', title: 'Are you sure you want to reset the SCA Configuration' });
            } else {
                setSCA();
            }
        }
    };

    const handleNo = () => {
        setIsOpen(false);
    };
    const handleYes = () => {
        if (resetForm?.type === 'sast') {
            setSAST();
            setIsOpen(false);
        }
        if (resetForm?.type === 'sca') {
            setSCA();
            setIsOpen(false);
        }
    };

    return (
        <Box mt={0}>
            <Box mb={2} sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: '600' }} id="autoinflateRadioGrp">
                Select the scan options *
            </Box>
            <Box display="flex" flexDirection="row" marginTop="-20px" justifyContent="start" gap={8}>
                <Box display="flex" alignItems="center">
                    <FormControlLabel
                        control={<Checkbox onChange={(e) => handleChange(e, 'sast')} checked={order?.onboardSAST} />}
                        label={
                            <InputLabel htmlFor="short-description">
                                <Grid sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700 }} item xs={6} sm={6}>
                                    SAST
                                </Grid>
                            </InputLabel>
                        }
                    />
                    <InfoTooltip
                        maxWidth="300px"
                        title={
                            <>
                                <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                                    <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                                    Application Security Testing (SAST) is the process of making applications more resistant to security
                                                    threats, by identifying security weaknesses and vulnerabilities in source code..
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </Box>
                <Box display="flex" alignItems="center">
                    <FormControlLabel
                        control={<Checkbox onChange={(e) => handleChange(e, 'sca')} checked={order?.onboardSCA} />}
                        label={
                            <InputLabel htmlFor="short-description">
                                <Grid sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700 }} item xs={6} sm={6}>
                                    SCA
                                </Grid>
                            </InputLabel>
                        }
                    />
                    <InfoTooltip
                        maxWidth="400px"
                        title={
                            <>
                                <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                                    <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                                <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                                    Software Composition Analysis (SCA) is an application security methodology for managing open source
                                                    components. It helps to determine any open-source component brought into a project and detect software
                                                    licenses, deprecated dependencies, vulnerabilities and potential exploits.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            </>
                        }
                    />
                </Box>
            </Box>
            <ServiceDialog isOpen={isOpen} handleNo={handleNo} handleYes={handleYes} title={resetForm?.title} />
        </Box>
    );
};

export default React.memo(FortySnykOnboardQuestion);
