import React from 'react';
import { Grid, Box, TableContainer, Table, TableHead, TableBody, Typography, Divider } from '@mui/material';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRecoilState } from 'recoil';
import Forms from 'pages/project-onboarding/components/Form';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import AddButton from 'pages/project-onboarding/components/Form/SastSca/AddButton';
import { TeamName } from '../TeamName';
import ServiceDialog from './ServiceDialog';

const SecurityToolConfiguration = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [sastDeleteMember, setSASTDeleteMember] = React.useState(null);
    const [scaDeleteMember, setSCADeleteMember] = React.useState(null);
    const [applicationVersion, setDeleteApplicationVersion] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);

    const handleSASTDeleteMember = () => {
        if (sastDeleteMember?.gpid) {
            setOrder({
                ...order,
                fortifyMembers: order?.fortifyMembers?.filter((item) => item.gpid !== sastDeleteMember?.gpid),
                sastMemberDetails: null,
            });
            setSASTDeleteMember(null);
            setValidForm({ ...isValidForm, isSASTMaxMemberLimit: false });
        }
    };

    const handleSCADeleteMember = () => {
        if (scaDeleteMember?.gpid) {
            setOrder({
                ...order,
                snykMembers: order?.snykMembers?.filter((item) => !(item.gpid === scaDeleteMember?.gpid && item?.role === scaDeleteMember?.role)),
            });
            setSCADeleteMember(null);
            setValidForm({ ...isValidForm, isSCAMaxMemberLimit: false });
        }
    };

    const handleDeleteApplicationVersion = () => {
        setOrder({
            ...order,
            applicationNameWithVersions: order?.applicationNameWithVersions?.filter(
                (item) => item.applicationVersion !== applicationVersion?.applicationVersion
            ),
        });
        setDeleteApplicationVersion(null);
        setValidForm({ ...isValidForm, isDuplicateSastApplication: false });
    };

    const handleAddApplication = () => {
        if (order?.sastApplicationName && order?.sastVersion) {
            const sastApplicationDetails = { applicationName: order?.sastApplicationName, applicationVersion: order?.sastVersion };
            const isDuplicate = order?.applicationNameWithVersions?.find(
                (item) => item.applicationName === order?.sastApplicationName && item.applicationVersion === order?.sastVersion
            );
            if (isDuplicate) {
                return setValidForm({ ...isValidForm, isDuplicateSastApplication: true });
                // eslint-disable-next-line no-else-return
            } else {
                setOrder({
                    ...order,
                    applicationNameWithVersions: [...order?.applicationNameWithVersions, sastApplicationDetails],
                    sastApplicationDetails: null,
                    sastVersion: null,
                });
                setValidForm({ ...isValidForm, isDuplicateSastApplication: false });
            }

            setValidForm({ ...isValidForm, isDuplicateSASTMember: false });
        }
        return null;
    };

    const handleNo = () => {
        setSASTDeleteMember(null);
        setSCADeleteMember(null);
        setDeleteApplicationVersion(null);
        setIsOpen(false);
    };
    const handleYes = () => {
        if (sastDeleteMember) {
            handleSASTDeleteMember();
            setIsOpen(false);
        }
        if (applicationVersion) {
            handleDeleteApplicationVersion();
            setIsOpen(false);
        }
        if (scaDeleteMember) {
            handleSCADeleteMember();
            setIsOpen(false);
        }
    };

    return (
        <Box data-testid="security-tool-config">
            <Grid container spacing={4} data-testid="security-tool-config-fields">
                <Grid item xs={12} sm={12} mb={2}>
                    <Forms.FortySnykOnboardQuestion />
                </Grid>
            </Grid>
            {order?.onboardSAST && (
                <>
                    <Grid container spacing={0} mt={1} data-testid="security-tool-config-fields">
                        <Typography variant="h6" mb={4.5} ml={0.5}>
                            SAST *
                        </Typography>
                        <Grid item xs={12} sm={12} mb={2} mt={2} alignItems="flex-start">
                            <Forms.SASTOptions />
                        </Grid>
                        {order?.fortifyOption === 'AccessExistingApplication' && (
                            <>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    mb={2}
                                    display="flex"
                                    gap={2}
                                    mt={6}
                                    sx={{
                                        border: isValidForm?.isDuplicateSastApplication ? '1px solid #ff3939' : '1px solid #0047BA',
                                        padding: '15px 15px 0px 15px',
                                        borderRadius: '7px',
                                    }}
                                >
                                    <Grid item xs={6} sm={6} mb={2}>
                                        <Forms.SASTApplicationNameSelect />
                                    </Grid>
                                    <Grid item xs={6} sm={6} mb={2}>
                                        <Forms.SASTVersionSelect />
                                    </Grid>
                                    <Box mr={0.5}>
                                        <AddButton handleAddApplication={() => handleAddApplication()} />
                                    </Box>
                                </Grid>
                                {isValidForm?.isDuplicateSastApplication && (
                                    <span style={{ color: '#ff3939', fontSize: '0.75rem', margin: '0 0 10px 10px' }}>Duplicate values not allowed</span>
                                )}
                            </>
                        )}
                        {order?.fortifyOption === 'CreateNewVersionInExistingApplication' && (
                            <Grid item xs={12} sm={12} mb={2} display="flex" gap={2} mt={6}>
                                <Grid item xs={6} sm={6} mb={0}>
                                    <Forms.SASTApplicationNameSelect />
                                </Grid>
                                <Grid item xs={6} sm={6} mb={0}>
                                    <Forms.SASTVersion />
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                    {order?.fortifyOption === 'AccessExistingApplication' && (
                        <Grid>
                            <Box display="flex" flexDirection="column" mt={0}>
                                <Divider />
                                <Box display="flex">
                                    {order?.applicationNameWithVersions?.length > 0 ? (
                                        <Box width="100%">
                                            <TableContainer>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Application</TableCell>
                                                            <TableCell>Version</TableCell>
                                                            <TableCell>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {order?.applicationNameWithVersions &&
                                                            order?.applicationNameWithVersions?.length &&
                                                            order?.applicationNameWithVersions?.map((item, index) => (
                                                                <TableRow key={index}>
                                                                    <TableCell align="left" width="1">
                                                                        <Typography>{item?.applicationName || ''}</Typography>
                                                                    </TableCell>
                                                                    <TableCell align="left" width="1">
                                                                        {item?.applicationVersion || '-'}
                                                                    </TableCell>
                                                                    <TableCell width="1">
                                                                        <DeleteIcon
                                                                            sx={ProjectOnboardingStyles?.removeBtn}
                                                                            color="error"
                                                                            onClick={() => {
                                                                                setDeleteApplicationVersion(item);
                                                                                setIsOpen(true);
                                                                            }}
                                                                            className="animate-opacity"
                                                                        />
                                                                    </TableCell>
                                                                </TableRow>
                                                            ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </Box>
                                    ) : (
                                        <Box mt={2}>
                                            <Typography variant="body1">No Application Selected</Typography>
                                        </Box>
                                    )}
                                </Box>
                                <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                                    {order?.applicationNameWithVersions && order?.applicationNameWithVersions?.length > 0 ? (
                                        <Typography>
                                            <b>{order?.applicationNameWithVersions?.length}</b>{' '}
                                            {order?.applicationNameWithVersions?.length < 2 ? 'Application' : 'Applications'} Added
                                        </Typography>
                                    ) : (
                                        <>&nbsp;</>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    )}{' '}
                    {order?.fortifyOption === 'CreateNewApplication' && (
                        <>
                            <Grid item xs={12} sm={12} mb={2} display="flex" gap={2} mt={6}>
                                <Grid item xs={6} sm={6} mb={0}>
                                    <Forms.SASTApplicationName />
                                </Grid>
                                <Grid item xs={6} sm={6} mb={0}>
                                    <Forms.SASTVersion />
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {order?.onboardSAST && order?.fortifyOption !== null && (
                        <Grid item xs={6} sm={6} mb={2} paddingTop={0} mt={2}>
                            <Forms.SASTMember />
                        </Grid>
                    )}
                    {order?.fortifyOption !== null && (
                        <Box display="flex" flexDirection="column" mt={0}>
                            <Box display="flex" mt={2}>
                                <Typography variant="h6">SAST Member Access List *</Typography>
                            </Box>
                            <Divider />
                            <Box display="flex">
                                {order?.fortifyMembers?.length > 0 ? (
                                    <Box width="100%">
                                        <TableContainer>
                                            <Table size="small">
                                                <TableBody>
                                                    {order?.fortifyMembers &&
                                                        order?.fortifyMembers?.length &&
                                                        order?.fortifyMembers?.map((item, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell align="left" width="1">
                                                                    <Typography>{item?.gpid || ''}</Typography>
                                                                </TableCell>
                                                                <TableCell align="left" width="1">
                                                                    {item?.name || '-'}
                                                                </TableCell>
                                                                <TableCell align="left" width="1">
                                                                    {item?.email === 'null' || !item?.email ? '-' : item?.email}
                                                                </TableCell>
                                                                <TableCell width="1">
                                                                    <DeleteIcon
                                                                        sx={ProjectOnboardingStyles?.removeBtn}
                                                                        color="error"
                                                                        onClick={() => {
                                                                            setSASTDeleteMember(item);
                                                                            setIsOpen(true);
                                                                        }}
                                                                        className="animate-opacity"
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                ) : (
                                    <Box mt={2}>
                                        <Typography variant="body1">No Members Selected</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                                {order?.fortifyMembers && order?.fortifyMembers?.length > 0 ? (
                                    <Typography>
                                        <b>{order?.fortifyMembers?.length}</b> {order?.fortifyMembers?.length < 2 ? 'Member' : 'Members'} Added{' '}
                                    </Typography>
                                ) : (
                                    <>&nbsp;</>
                                )}
                            </Box>
                        </Box>
                    )}
                </>
            )}{' '}
            {order?.onboardSCA && (
                <>
                    <Grid container spacing={0} mt={4} data-testid="security-tool-config-fields">
                        <Typography variant="h6" ml={0.5}>
                            SCA *
                        </Typography>
                        <Grid item xs={12} sm={12} mb={2} display="flex" gap={2} mt={3}>
                            <Grid item xs={6} sm={6} mb={2}>
                                <TeamName />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm={12} mb={2} display="flex" gap={2}>
                            <Grid item xs={6} sm={6} mb={2}>
                                <Forms.SCARoles />
                            </Grid>
                            <Grid item xs={6} sm={6} mb={2}>
                                <Forms.SCAMember />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box display="flex" flexDirection="column" mt={0}>
                        <Box display="flex" mt={2}>
                            <Typography variant="h6">SCA Member Access List *</Typography>
                        </Box>
                        <Divider />
                        <Box display="flex">
                            {order?.snykMembers?.length > 0 ? (
                                <Box width="100%">
                                    <TableContainer>
                                        <Table size="small">
                                            <TableBody>
                                                {order?.snykMembers &&
                                                    order?.snykMembers?.length &&
                                                    order?.snykMembers?.map((item, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell align="left" width="1">
                                                                <Typography>{item?.gpid || ''}</Typography>
                                                            </TableCell>
                                                            <TableCell align="left" width="1">
                                                                {item?.name || '-'}
                                                            </TableCell>
                                                            <TableCell align="left" width="1">
                                                                {item?.role || '-'}
                                                            </TableCell>
                                                            <TableCell width="1">
                                                                <DeleteIcon
                                                                    sx={ProjectOnboardingStyles?.removeBtn}
                                                                    color="error"
                                                                    onClick={() => {
                                                                        setSCADeleteMember(item);
                                                                        setIsOpen(true);
                                                                    }}
                                                                    className="animate-opacity"
                                                                />
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            ) : (
                                <Box mt={2}>
                                    <Typography variant="body1">No Members Selected</Typography>
                                </Box>
                            )}
                        </Box>
                        <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                            {order?.snykMembers && order?.snykMembers?.length > 0 ? (
                                <Typography>
                                    <b>{order?.snykMembers?.length}</b> {order?.snykMembers?.length < 2 ? 'Member' : 'Members'} Added{' '}
                                </Typography>
                            ) : (
                                <>&nbsp;</>
                            )}
                        </Box>
                    </Box>
                </>
            )}
            <ServiceDialog isOpen={isOpen} member={sastDeleteMember?.name || scaDeleteMember?.name} handleNo={handleNo} handleYes={handleYes} />
        </Box>
    );
};

export default React.memo(SecurityToolConfiguration);
