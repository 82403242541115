import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm, toggleModal } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import Btn from 'components/button';

const AddMember = ({ handleAddMember, isLoading }) => {
    const [order] = useRecoilState(OrderPayload);
    const [isOpen] = useRecoilState(toggleModal);
    const [isValidForm] = useRecoilState(validOnboardingForm);

    const isDisabled = () => {
        let isFlag = false;
        if (isOpen?.isProjectAdminOpen) {
            isFlag =
                isValidForm?.isDuplicateAdminAccessMember ||
                isValidForm?.isAdminAccessMaxMemberLimit ||
                !order?.adminAccessMember ||
                !order?.adminAccessMemberDetails;
        } else if (isOpen?.isFortifySnykOpen) {
            isFlag = isValidForm?.isDuplicateSCAMember || isValidForm?.isSCAMaxMemberLimit || !order?.snykMemberDetails;
        } else if (isOpen?.isTestPlanOpen) {
            isFlag = isValidForm?.isDuplicateTestPlanUser || isValidForm?.isTestPlanMaxMemberLimit || !order?.testPlanUser || !order?.testPlanUserDetails;
        } else if (isOpen?.isSonarQubeOpen) {
            isFlag = isValidForm?.isDuplicateSonarQubeUser || isValidForm?.isSonarQubeMaxMemberLimit || !order?.sonarQubeUser || !order?.sonarQubeUserDetails;
        } else if (isOpen?.isArtifactoryOpen) {
            isFlag =
                isValidForm?.isDuplicateArtifactoryUser ||
                isValidForm?.isArtifactoryMaxMemberLimit ||
                !order?.artifactoryUser ||
                !order?.artifactoryUserDetails;
        }
        return isFlag;
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px' }}>
            <Btn
                size="small"
                onClick={() => handleAddMember()}
                variant="contained"
                disabled={isDisabled()}
                sx={ProjectOnboardingStyles?.addBtn}
                color="cmpPrimary"
                data-testid="add-member-btn"
            >
                {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Add'}
            </Btn>
        </Box>
    );
};

AddMember.propTypes = {
    handleAddMember: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

AddMember.defaultProps = {
    isLoading: false,
};

export default React.memo(AddMember);
