import { styled } from '@mui/material/styles';
import { TableRow, RadioGroup, Radio, Box } from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

const ProjectOnboardingStyles = {
    projectOnboarding_wrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: (theme) => theme.spacing('100%'),
        height: 'calc(100vh - 100px)',
    },
    circularProgressColor: { color: (theme) => theme.palette.black.dark },
    infoStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        headingStyle: { fontWeight: 600, fontSize: '12px' },
        topLeftNamingRule: { mb: (theme) => theme.spacing(1) },
        topRightNamingRule: { mb: (theme) => theme.spacing(1) },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', fontSize: '12px' },
    },
    helpImgStyle: {
        wrapper: { display: 'flex', flexDirection: 'column', height: '100%', padding: '10px' },
        namingRuleWrapper: { flexGrow: 1, overflow: 'auto' },
        namingRule: { mb: (theme) => theme.spacing(1) },
        topDividerRule: { borderBottom: '1px solid #AFB8CC' },
        bottomDividerRule: { borderTop: '1px solid #AFB8CC' },
        typographyStyle: { display: 'block', vaiant: (theme) => theme.typography.caption.fontSize },
        emptyLine: { marginBottom: '5px' },
    },
    formField: {
        paddingTop: '15px !important',
        paddingLeft: '32px',
    },
    radioFields: {
        radioText: { fontSize: '12px', fontFamily: 'Roboto', fontWeight: 'normal' },
        stack: { alignItems: 'center', justifyContent: 'flex-start' },
        radioBtnNoForm: {
            '& .css-j204z7-MuiFormControlLabel-root label': {
                marginRight: (theme) => theme.spacing(1.25),
            },
        },
    },
    addBtn: {
        width: (theme) => theme.spacing(18.75),
        height: (theme) => theme.spacing(4.375),
    },
    resubmitBtn: {
        width: (theme) => theme.spacing(20.75),
        height: (theme) => theme.spacing(4.375),
    },
    stack: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    error: {
        color: '#F4273B !important',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 'normal',
    },
    modal: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        groupWrapper: { width: '100%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3.375),
            height: (theme) => theme.spacing(3.375),
            background: '#F4273B',
            mt: 0.5,
        },
        delete: {
            fontSize: (theme) => theme.spacing(2.25),
            fill: '#FFFFFF',
            color: (theme) => theme.palette.white,
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
        add: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
    },
    MemberAction: {
        select: { width: '100%' },
        searchIcon: { cursor: 'pointer', color: '#0047BA' },
        infoicon: { color: '#0047BA' },
    },
    submitOrder: {
        wrapper: { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' },
        orderIdTypo: { color: (theme) => theme.palette.blue.main, textDecoration: 'underline', cursor: 'pointer', mx: (theme) => theme.spacing(1) },
        actionWrapper: { textAlign: 'center', my: (theme) => theme.spacing(0.25), py: (theme) => theme.spacing(2) },
        newOrder: { marginRight: (theme) => theme.spacing(2) },
        message: { fontSize: '30px', fontWeight: 500, fontFamily: 'Stolzl', color: (theme) => theme.palette.black.dark },
        description: { width: '500px', fontSize: '16px', fontWeight: 400, fontFamily: 'Roboto', color: (theme) => theme.palette.black.dark },
    },
    removeBtn: {
        cursor: 'pointer',
        color: '#F4273B',
    },
    table: {
        tableWrapper: {
            marginTop: '4px',
            // borderRadius: '3px',
            // boxShadow: '1px 1px 2px #00000014',
        },
        tableRow: {
            background: 'transparent !important',
            border: '0 !important',
        },
        tableCell: {
            border: '0 !important',
        },
        main: {
            marginTop: '-9px',
            borderSpacing: '0 4px',
            minWidth: 500,
        },
    },
    emptyStack: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    codeRepoStyle: {
        wrapper: { minWidth: (theme) => theme.spacing(60), width: 'auto', display: 'flex', height: '100%', flexDirection: 'column', overflow: 'hidden' },
        contentWrapper: { overflow: 'auto', pt: (theme) => theme.spacing(2), height: '100%' },
        rowWrapper: { display: 'flex', mb: (theme) => theme.spacing(2), width: '100%' },
        codeRepoWrapper: { width: '200%' },
        iconWrapper: { width: '10%', px: (theme) => theme.spacing(1) },
        delete: {
            fontSize: '1.125rem',
            fill: '#F4273B',
            color: '#F4273B',
            cursor: 'pointer',
            width: (theme) => theme.spacing(1.5),
            height: (theme) => theme.spacing(1.625),
        },
        add: { cursor: 'pointer', marginTop: '7px', fontSize: '25px' },
        actionWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'flex-end', p: (theme) => theme.spacing(2) },
        warningTypography: { color: (theme) => theme.palette.red.light },
        deleteWrapper: {
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: (theme) => theme.spacing(3.375),
            height: (theme) => theme.spacing(3.375),
            mt: 0.5,
        },
    },
    preview: {
        header: {
            previewDetailsTextWrap: { paddingRight: 3 },
            previewDetailsText: { fontSize: '21px', color: '#021D49', fontWeight: '600', fontFamily: 'Roboto' },
        },
        editBtn: {
            // border: ' 1px solid #707070',
            borderRadius: '4px',
            // background: '#FFFFFF 0% 0% no-repeat padding-box',

            width: (theme) => theme.spacing(23),
            height: (theme) => theme.spacing(5),
            fontSize: '16px !important',
            fontWeight: 'medium',
            fontFamily: 'Roboto !important',
            textTransform: 'none',
        },
        wrapper: {
            padding: '20px',
            background: '#FFFFFF',
            color: '#616161',
            fontWeight: 400,
            fontFamily: 'Roboto',
            border: '1px solid #CCCCCC',
            borderRadius: '6px',
        },
        orderMemberWrapper: {
            background: '#FFFFFF',
            color: '#616161',
            fontWeight: 400,
            fontFamily: 'Roboto',
            border: '1px solid #CCCCCC',
            overflow: 'scroll',
            maxHeight: '405px',
            borderRadius: '6px',
            '::-webkit-scrollbar-thumb': {
                backgroundColor: '#c8c8c8 !important',
            },
        },
        heading: { color: '#0047BA', fontFamily: 'Stolzl', fontWeight: 500, fontSize: '21px' },
        divder: { color: '#707070', marginTop: 1, marginBottom: 1 },
        memberAccess: {
            container: { background: '#F8F8F8', padding: '15px', mb: '15px' },
            orderMemberContainer: { background: '#F8F8F8', padding: '15px', mb: '10px' },
            subWrapper: { display: 'flex', alignItems: 'center' },
            label: { width: '170px' },
            value: { fontWeight: 500, wordBreak: 'break-all' },
            colon: { px: '10px' },
            doneIcon: { color: '#10a700', fontSize: 'x-large' },
        },
        projectDetails: {
            container: { py: '10px' },
            subWrapper: { display: 'flex', flexDirection: 'column' },
            label: { fontWeight: 500, fontFamily: 'Roboto', fontSize: '18px', color: '#1E1E1E' },
            value: { fontWeight: 400, fontFamily: 'Roboto', fontSize: '16px', color: '#616161' },
        },
        fs: {
            container: { py: '10px' },
            subWrapper: { display: 'flex' },
            label: { fontWeight: 500, fontFamily: 'Roboto', fontSize: '18px', color: '#1E1E1E', width: '205px' },
            value: { fontWeight: 400, fontFamily: 'Roboto', fontSize: '16px', color: '#616161' },
        },
    },
    costSummary: {
        button: {
            width: '100%',
            height: (theme) => theme.spacing(5.625),
            marginRight: (theme) => theme.spacing(6),
            marginLeft: (theme) => theme.spacing(6),
            fontSize: '16px !important',
            fontWeight: 'medium',
            fontFamily: 'Roboto !important',
            textTransform: 'none',
            minWidth: '250px',
        },
        previewButton: {
            width: '100%',
            height: (theme) => theme.spacing(5.625),
            marginRight: (theme) => theme.spacing(6),
            marginLeft: (theme) => theme.spacing(6),
            fontSize: '16px !important',
            fontWeight: 'medium',
            fontFamily: 'Roboto !important',
            textTransform: 'none',
            backgroundColor: '#FFFFFF',
            '&.MuiButtonBase-root:hover': {
                backgroundColor: '#FFFFFF',
            },
        },
    },
    buttonActionStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        gap: '10px',
    },
    fixedSummary: {
        position: 'fixed',
        top: '80px',
        right: '60px',
        paddingLeft: '14px',
        width: '30%',
        zIndex: 0,
    },
    adminList: {
        display: 'flex',
        overflow: 'scroll',
        maxHeight: '175px',
        '::-webkit-scrollbar-thumb': {
            backgroundColor: '#c8c8c8 !important',
        },
    },
};
export default ProjectOnboardingStyles;

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#0047BA',
        color: theme.palette.white,
        fontSize: '16px',
        padding: '0px',
        border: 0,
        paddingLeft: 15,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        color: '#242424',
        fontWeight: 'normal',
    },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.white,
    border: 0,
    '&:nth-of-type(1) td': {
        paddingTop: '10px',
    },
    '&:hover': {
        backgroundColor: '#E5F5FC',
    },
    // hide last border
    '&:last-child td, &:last-child th': {},
}));

export const CustomRadioGroup = styled(RadioGroup)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'left',
    width: 'inherit',
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.25),
        marginRight: theme.spacing(-2.15),
    },
    '& .MuiSvgIcon-root': {
        [theme.breakpoints.only('md')]: {
            fontSize: theme.spacing(1.875),
        },
        [theme.breakpoints.only('lg')]: {
            fontSize: theme.spacing(2.25),
        },
    },
}));

export const CustomRadio = styled(Radio)(({ theme }) => ({
    [theme.breakpoints.only('md')]: {
        fontSize: theme.spacing(1.875),
    },
    [theme.breakpoints.only('lg')]: {
        fontSize: theme.spacing(2.25),
    },
}));

export const ScrollableContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    // height: '100vh',
    // overflowY: 'auto',
    paddingTop: theme.spacing(2.25),
    paddingBottom: theme.spacing(3.75),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
}));

export const LeftBox = styled(Box)(({ theme }) => ({
    width: '100%',
    height: 'auto',
    paddingRight: theme.spacing(2),
}));

export const StickyRightBox = styled(Box)({
    width: '100%',
    height: '100vh',
    position: 'sticky',
    top: 0,
});

export const ScrollableContent = styled(Box)({
    // height: '200vh',
});
