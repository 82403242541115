import React from 'react';
import { FormControl, FormControlLabel, Box, Stack } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, toggleModal } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles, { CustomRadioGroup, CustomRadio } from 'pages/project-onboarding/style';

const SASTOptions = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [isOpen] = useRecoilState(toggleModal);

    const setSASTOption = (fortifyOption) => {
        setOrder({
            ...order,
            fortifyOption,
            sastVersion: null,
            sastApplicationName: null,
            applicationNameWithVersions: [],
            fortifyMembers: [],
        });
    };

    const handleOnSelectChange = (selected) => {
        setSASTOption(selected);
    };

    const getValidate = () => {
        if (isOpen?.isFortifySnykOpen && !order?.fortifyOption && order?.isConfigureTouched) return 'ritmCheckbox';
        return '';
    };

    return (
        <Stack direction="row" spacing={1} sx={ProjectOnboardingStyles.stack}>
            <FormControl sx={{ marginLeft: '5px', height: '36px', justifyContent: 'center', width: '100%' }}>
                <Box sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: '600' }} id="autoinflateRadioGrp">
                    Select the SAST Onboarding Option *
                </Box>
                <CustomRadioGroup
                    data-testid="security-tools-radio-group"
                    aria-label="availableClarityId"
                    name="availableClarityIdRadioBtnGroup"
                    row
                    value={order?.fortifyOption || null}
                    onChange={(event, value) => handleOnSelectChange(value)}
                    className={getValidate()}
                    sx={{ display: 'flex', flexDirection: 'column' }}
                >
                    <FormControlLabel
                        value="AccessExistingApplication"
                        control={<CustomRadio className={getValidate()} />}
                        label="Request access for existing application in SAST"
                    />
                    <FormControlLabel
                        value="CreateNewApplication"
                        control={<CustomRadio className={getValidate()} />}
                        label="Create a new application in SAST"
                    />
                    {/* <FormControlLabel
                        value="CreateNewVersionInExistingApplication"
                        control={<CustomRadio className={getValidate()} />}
                        label="Create a new version of existing application in SAST"
                    /> */}
                </CustomRadioGroup>
            </FormControl>
        </Stack>
    );
};

export default React.memo(SASTOptions);
