import React from 'react';
import { Box, Checkbox, FormControlLabel, InputLabel, Grid } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

const NewProjectOnboardQuestion = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);

    const handleChange = (e, type) => {
        if (type === 'members') {
            setOrder({
                ...order,
                newProjectOnboardMembers: !order?.newProjectOnboardMembers,
                sonarQubeUserList: order?.newProjectOnboardMembers ? [] : order?.sonarQubeUserList,
            });
        }
        if (type === 'serviceAccount') {
            setOrder({
                ...order,
                newProjectOnboardServiceAccount: !order?.newProjectOnboardServiceAccount,
                serviceAccount: null,
            });
        }
    };

    return (
        <>
            <Box mb={2} mt={1} sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: '600' }} id="autoinflateRadioGrp">
                What would you like to Onboard in SonarQube? *
            </Box>
            <Box display="flex" flexDirection="row" marginTop="-20px" justifyContent="start" gap={8}>
                <Box>
                    <FormControlLabel
                        control={<Checkbox onChange={(e) => handleChange(e, 'serviceAccount')} checked={order?.newProjectOnboardServiceAccount} />}
                        label={
                            <InputLabel htmlFor="short-description">
                                <Grid sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700 }} item xs={6} sm={6}>
                                    Service account
                                </Grid>
                            </InputLabel>
                        }
                    />
                </Box>
                <Box>
                    <FormControlLabel
                        control={<Checkbox onChange={(e) => handleChange(e, 'members')} checked={order?.newProjectOnboardMembers} />}
                        label={
                            <InputLabel htmlFor="short-description">
                                <Grid sx={{ color: '#0047BA', fontSize: '12px', fontFamily: 'Roboto', fontWeight: 700 }} item xs={6} sm={6}>
                                    Members
                                </Grid>
                            </InputLabel>
                        }
                    />
                </Box>
            </Box>
        </>
    );
};

export default React.memo(NewProjectOnboardQuestion);
