import React from 'react';
import { Box, IconButton, CircularProgress } from '@mui/material';
import { CssTextField } from 'components/select';
import SearchIcon from '@mui/icons-material/Search';
import { useMutation } from 'react-query';
import axios, { endPoints } from 'api/request';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm, toggleModal, isSonarQubeRepoAvailable } from 'pages/project-onboarding/store';
import { resetValidOnboardingForm, resetToggleModal } from 'pages/project-onboarding/utils';

export const ClarityId = () => {
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [inValidText, setInValidText] = React.useState(false);
    const [idNotFoundText, setIdNotFoundText] = React.useState(false);
    const [projectUnavailableText, setProjectUnavailableText] = React.useState(false);
    const [, setValidProvisionForm] = useRecoilState(validOnboardingForm);
    const [, setToggleModal] = useRecoilState(toggleModal);
    const [, setRepoAvailable] = useRecoilState(isSonarQubeRepoAvailable);

    const clarityDetailsApi = useMutation(() => axios.get(`${endPoints.day2Operation.ProjectOnboarding.clarityId?.replace('{clarityId}', order?.clarityId)}`));

    React.useEffect(() => {
        if (clarityDetailsApi?.isSuccess) {
            if (clarityDetailsApi?.data?.data) {
                if (clarityDetailsApi?.data?.data?.devopsProject) {
                    setProjectUnavailableText(false);
                    setValidForm({ ...validForm, isClarityIdValid: true });
                    setOrder({
                        ...order,
                        clarityDetails: clarityDetailsApi?.data?.data || null,
                        existingProjectName: clarityDetailsApi?.data?.data?.devopsProject,
                    });
                } else {
                    setValidForm({ ...validForm, isClarityIdValid: false });
                    setProjectUnavailableText(true);
                    setOrder({
                        ...order,
                        clarityDetails: null,
                        projectName: null,
                        existingProjectName: null,
                    });
                }
                setIdNotFoundText(false);
            } else {
                setIdNotFoundText(true);
                setProjectUnavailableText(false);
                setValidForm({ ...validForm, isClarityIdValid: false });
                setOrder({
                    ...order,
                    clarityDetails: null,
                    projectName: null,
                    existingProjectName: null,
                });
            }
        }
    }, [clarityDetailsApi?.isSuccess]);

    const handleSearch = () => {
        if (order?.isClarityId === 'yes' && !inValidText) {
            setOrder({
                ...order,
                clarityDetails: null,
                adoProjectType: null,
                projectDetails: null,
                existingAdminMembers: null,
                serviceResources: {
                    ...order.serviceResources,
                    isProjectAdmin: false,
                    isBoard: false,
                    isRepos: false,
                    isPipelines: false,
                    isSonarQube: false,
                    isArtifactory: false,
                    isSecurityTools: false,
                    isTestPlan: false,
                },
            });
            setValidProvisionForm(resetValidOnboardingForm);
            setToggleModal(resetToggleModal);
            setRepoAvailable(true);
            clarityDetailsApi.mutate();
        }
    };

    React.useEffect(() => {
        if (
            order?.isClarityId === 'yes' &&
            order?.clarityId &&
            order?.clarityId !== null &&
            order?.clarityId !== '' &&
            order?.clarityId !== undefined &&
            !/^PR[0-9]{6,8}$/.test(order?.clarityId)
        ) {
            setInValidText(true);
            setIdNotFoundText(false);
            setProjectUnavailableText(false);

            setOrder({
                ...order,
                projectName: null,
                existingProjectName: null,
                adoProjectType: null,
                isTouched: false,
            });
        } else {
            setInValidText(false);
            setIdNotFoundText(false);
            setProjectUnavailableText(false);
        }
    }, [order?.clarityId]);

    const validateInput = () => {
        if (inValidText || (order?.isClarityId && !order?.clarityId && order?.isTouched)) {
            return 'validate';
        }
        return '';
    };

    const getText = () => {
        if (inValidText) return "Clarity Id must start with 'PR' followed by numbers within 6 to 8 chars.";
        if (idNotFoundText) return 'Clarity Id not found';
        if (projectUnavailableText) return 'No ADO Project is registered for this id in clarity.';
        return '';
    };

    React.useEffect(() => {
        let clarityIdVal = '';
        if (order?.isClarityId === 'yes') {
            clarityIdVal = order?.clarityId;
        } else if (order?.isClarityId === 'no') {
            clarityIdVal = '**NO PPMID**';
        } else clarityIdVal = '';
        setOrder({
            ...order,
            clarityId: clarityIdVal,
        });
    }, [order?.isClarityId]);

    return (
        <Box display="flex" alignItems="center" gap={1} id="tes" marginRight={3.4}>
            <CssTextField
                data-testid="clarityId"
                fullWidth
                label="Clarity ID"
                isLoading
                autoComplete="off"
                value={order?.clarityId || ''}
                size="small"
                disabled={order?.isClarityId !== 'yes'}
                error={inValidText || idNotFoundText || projectUnavailableText}
                helperText={getText()}
                onChange={(e) => {
                    setOrder({
                        ...order,
                        clarityId: e.target.value?.toUpperCase(),
                        clarityDetails: null,
                    });
                }}
                onBlur={() => {
                    if (!validForm?.isClarityIdValid) {
                        setOrder({
                            ...order,
                            clarityId: null,
                            clarityDetails: null,
                        });
                    }
                }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <>
                            <IconButton
                                onMouseDown={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    handleSearch();
                                }}
                            >
                                {clarityDetailsApi?.isLoading ? (
                                    <CircularProgress color="grey" size={20} />
                                ) : (
                                    <SearchIcon sx={{ cursor: 'pointer', color: '#A5A5A5' }} />
                                )}
                            </IconButton>
                        </>
                    ),
                }}
                className={validateInput()}
            />
        </Box>
    );
};

export default React.memo(ClarityId);
