const peMapping = {
    'Isolated Private Endpoint (Qty)': 'Isolated Private Endpoint (Qty)',
    'Enterprise Private Endpoint (Qty)': 'Enterprise Private Endpoint (Qty)',
    SubType: 'SubType',
};
const MappingLabel = {
    // PE
    ...peMapping,
    // Other Configurations
    'Blob Mpe Name': 'BLOB MPE Name',
    blobMpeName: 'BLOB MPE Name',
    'Datalake Mpe Name': 'DATALAKE MPE Name',
    datalakeMpeName: 'DATALAKE MPE Name',
    'Datalake Storage Id': 'Datalake Storage ID',
    dataLakeStorageId: 'Datalake Storage ID',
    'Managed Vnet Ir Name': 'Managed VNET IR Name',
    managedVnetIrName: 'Managed VNET IR Name',
    'Adls Link Service Name': 'ADLS Link Service Name',
    adlsLinkServiceName: 'ADLS Link Service Name',
    'Dbk Link Service Name': 'DBK Link Service Name',
    dbkLinkServiceName: 'DBK Link Service Name',
    // Data Factory
    'Master Adf Name': 'Master ADF Name',
    masterAdfName: 'Master ADF Name',
    // Data Bricks Cluster
    'Dbkworkspace Id': 'DBK Workspace ID',
    workspaceId: 'Workspace ID',
    'Workspace Url': 'Workspace URL',
    workspaceUrl: 'Workspace URL',
    'Dbkhive Url': 'DBK Hive URL',
    hiveUrl: 'Hive URL',
    'Dbk Hive Url': 'DBK Hive URL',
    'Dbk Mpe Name': 'DBK MPE Name',
    mpeName: 'MPE Name',
    'Mpe Name': 'MPE Name',
    'Key Vault Link Service Name': 'KEYVAULT Link Service Name',
    keyVaultLinkServiceName: 'KEYVAULT Link Service Name',
    'Blob Link Service Name': 'BLOB Link Service Name',
    blobLinkServiceName: 'BLOB Link Service Name',
    Others: 'Other Configurations',
    'Adls Ad Group Id': 'ADLS Ad Group Id',
    idmRequestNumber: 'IDM Request No',
    metadataXML: 'Metadata XML',
    adoProjectName: 'ADO Project Name',
    projectAdminADGroup: 'Project Admin AD Group',
    adoProjectUrl: 'ADO Project URL',
    fortifyUrl: 'Fortify URL',
    snykUrl: 'Snyk URL',
    sonarQubeUrl: 'Sonarqube URL',
    serviceAccountRequestNumber: 'SonarQube Service Account Request',
    addMembersRequestNumber: 'Project Admin Add Members Request',
    'Metadata XML': 'Metadata XML',
    'De Dbk Cluster Name': 'DE DBK Cluster Name',
    'Ds Dbk Cluster Name': 'DS DBK Cluster Name',
    'Adls Container Name': 'ADLS Container Name',
    'Adf Dbk Link Service Name': 'ADF DBK Link Service Name',
    'Adf Dbk Mpe Name': 'ADF DBK MPE Name',
    // Added for RI001.A provsioing details
    dbkDeClusterName: 'DBK DE Cluster Name',
    dbkDsClusterName: 'DBK DS Cluster Name',
    adfDbkLinkServiceName: 'ADF DBK Link Service Name',
    adlsContainerName: 'ADLS Container Name',
    adfDbkMpeName: 'ADF DBK MPE Name',
    'Dbk Ds Cluster Name': 'DBK DS Cluster Name',
    'Dbk De Cluster Name': 'DBK DE Cluster Name',
    'Adf De Dbk Mpe Name': 'ADF DE DBK MPE Name',
    'Adf Ds Dbk Mpe Name': 'ADF DS DBK MPE Name',
    'Adf De Dbk Link Service Name': 'ADF DE DBK Link Service Name',
    'Adf Ds Dbk Link Service Name': 'ADF DS DBK Link Service Name',
    'Adf De Managed Vnet Ir Name': 'ADF DE Managed VNET IR Name',
    'Adf Ds Managed Vnet Ir Name': 'ADF DS Managed VNET IR Name',
    // dbkDsClusterName: 'DBK DS Cluster Name',
    // dbkDeClusterName: 'DBK DE Cluster Name',
    adfDeDbkMpeName: 'ADF DE DBK MPE Name',
    adfDsDbkMpeName: 'ADF DS DBK MPE Name',
    adfDeDbkLinkServiceName: 'ADF DE DBK Link Service Name',
    adfDsDbkLinkServiceName: 'ADF DS DBK Link Service Name',
    adfDeManagedVnetIrName: 'ADF DE Managed VNET IR Name',
    adfDsManagedVnetIrName: 'ADF DS Managed VNET IR Name',
    dataFactory: 'dataFactoryType',
    dataBricksCluster: 'clusterType',
    'data-factory': 'data-factory-type',
    'data-lake-storage': 'storage-type',
    'data-bricks-cluster': 'data-bricks-cluster-type',
    Sector_adls_container_name: 'Sector ADLS Container Name',
    'Sector Adls Container Name': 'Sector ADLS Container Name',
    'Sector adls container name': 'Sector ADLS Container Name',
    sectorAdlsContainerName: 'Sector ADLS Container Name',
    dbkHiveUrl: 'DBK Hive URL',
    dbkMpeName: 'DBK MPE Name',
    mypamSafeName: 'Service Account myPAM Safe Name',
    mypamSafeOwnerGpid: 'Service Account myPAM Safe Owner GPID',
    sonarqubeUrl: 'SonarQube URL',
    sonarServiceConnection: 'SonarQube Service Connection',
    sonarProjectNamesList: 'SonarQube Project Names',
    scaUrl: 'SCA URL',
    sastUrl: 'SAST URL',
    sastAppName: 'SAST App Name',
    sastAppVersion: 'SAST App Version',
    sastRequestNumber: 'SAST MyIDM Request Number',
    scaAdminRequestNumber: 'SCA Admin MyIDM Request Number',
    scaCollaboratorRequestNumber: 'SCA Collaborator MyIDM Request Number',
};

export default MappingLabel;
