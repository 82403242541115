import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';
import { useRecoilState } from 'recoil';
import { OrderPayload, validOnboardingForm, toggleModal } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';
import Btn from 'components/button';

const AddSASTMember = ({ handleAddMember, isLoading }) => {
    const [order] = useRecoilState(OrderPayload);
    const [isOpen] = useRecoilState(toggleModal);
    const [isValidForm] = useRecoilState(validOnboardingForm);

    const isDisabled = () => {
        let isFlag = false;
        if (isOpen?.isFortifySnykOpen) {
            isFlag = isValidForm?.isDuplicateSASTMember || isValidForm?.isSASTMaxMemberLimit || !order?.sastMemberDetails;
        }
        return isFlag;
    };

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '60px' }}>
            <Btn
                size="small"
                onClick={() => handleAddMember()}
                variant="contained"
                disabled={isDisabled()}
                sx={ProjectOnboardingStyles?.addBtn}
                color="cmpPrimary"
                data-testid="add-member-btn"
            >
                {isLoading ? <CircularProgress color="inherit" size={16} /> : 'Add'}
            </Btn>
        </Box>
    );
};

AddSASTMember.propTypes = {
    handleAddMember: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
};

AddSASTMember.defaultProps = {
    isLoading: false,
};

export default React.memo(AddSASTMember);
