import React from 'react';
import { Box, Divider, Grid, Stack, TableCell, Typography, TableContainer, Table, TableHead, TableBody, TableRow } from '@mui/material';
import Style from 'pages/project-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';

const SecurityTool = () => {
    const [order] = useRecoilState(OrderPayload);
    const getSecurityToolRequired = (object) => {
        if (object?.onboardSAST && object?.onboardSCA) {
            return 'SAST, SCA';
        }
        if (object?.onboardSAST) {
            return 'SAST';
        }
        if (object?.onboardSCA) {
            return 'SCA';
        }
        return null;
    };

    const LabelValue = (label, value) => (
        <Grid item xs={6} sm={6}>
            <Box sx={Style?.preview?.projectDetails?.subWrapper}>
                <Box sx={Style?.preview?.projectDetails?.label}>{label}:</Box>
                <Box sx={Style?.preview?.projectDetails?.value}>{value || '-'}</Box>
            </Box>
        </Grid>
    );

    return (
        <>
            {(order?.onboardSAST || order?.onboardSCA) && (
                <Box sx={Style?.preview?.wrapper}>
                    <Box sx={Style?.preview?.heading}>{getSecurityToolRequired(order) === 'SAST, SCA' ? 'SAST/SCA' : getSecurityToolRequired(order)}</Box>
                    <Divider variant="horizontal" sx={Style?.preview?.divder} />
                    <Grid container sx={Style?.preview?.fs?.container}>
                        <Grid item xs={12} sm={12}>
                            <Grid container sx={Style?.preview?.projectDetails?.container} spacing={2}>
                                {LabelValue('Security Tool Requested', getSecurityToolRequired(order))}
                                {order?.teamName && LabelValue('SCA Organization', order?.teamName)}
                                {order?.fortifyOption && LabelValue('SAST Request Type', order?.fortifyOption?.replace(/([A-Z])/g, ' $1'))}
                            </Grid>
                            {order?.onboardSAST && (
                                <>
                                    <Box sx={Style?.preview?.fs?.label} minWidth={220}>
                                        SAST Application Version
                                    </Box>
                                    <Stack direction="column" spacing={1}>
                                        <Box sx={Style?.preview?.fs?.subWrapper} display="flex" flexDirection="column">
                                            <Box display="flex" flexDirection="column" mt={0}>
                                                <Divider />
                                                <Box display="flex">
                                                    {order?.applicationNameWithVersions?.length > 0 ? (
                                                        <Box width="100%">
                                                            <TableContainer>
                                                                <Table size="small">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell sx={{ fontSize: '14px', paddingLeft: 0 }}>Application</TableCell>
                                                                            <TableCell sx={{ fontSize: '14px', paddingLeft: 0 }}>Version</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        {order?.applicationNameWithVersions &&
                                                                            order?.applicationNameWithVersions?.length &&
                                                                            order?.applicationNameWithVersions?.map((item, index) => (
                                                                                <TableRow key={index}>
                                                                                    <TableCell align="left" width="1" sx={{ paddingLeft: 0 }}>
                                                                                        <Typography>{item?.applicationName || ''}</Typography>
                                                                                    </TableCell>
                                                                                    <TableCell align="left" width="1" sx={{ paddingLeft: 0 }}>
                                                                                        {item?.applicationVersion || '-'}
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            ))}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Box>
                                                    ) : (
                                                        <Box width="100%">
                                                            <TableContainer>
                                                                <Table size="small">
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell sx={{ fontSize: '14px', paddingLeft: 0 }}>Application</TableCell>
                                                                            <TableCell sx={{ fontSize: '14px', paddingLeft: 0 }}>Version</TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell align="left" width="1" sx={{ paddingLeft: 0 }}>
                                                                                <Typography>{order?.sastApplicationName || ''}</Typography>
                                                                            </TableCell>
                                                                            <TableCell align="left" width="1" sx={{ paddingLeft: 0 }}>
                                                                                {order?.sastVersion || '-'}
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableBody>
                                                                </Table>
                                                            </TableContainer>
                                                        </Box>
                                                    )}
                                                </Box>
                                                <Box display="flex" alignItems="baseline" justifyContent="space-between" mt={2}>
                                                    {order?.applicationNameWithVersions && order?.applicationNameWithVersions?.length > 0 ? (
                                                        <Typography>
                                                            <b>{order?.applicationNameWithVersions?.length}</b>{' '}
                                                            {order?.applicationNameWithVersions?.length < 2 ? 'Application' : 'Applications'} Added
                                                        </Typography>
                                                    ) : (
                                                        <>&nbsp;</>
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Stack>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            )}
        </>
    );
};

export default SecurityTool;
