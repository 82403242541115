import React from 'react';
import { Box, Divider } from '@mui/material';
import { getMemberAccessForPreview } from 'pages/project-onboarding/utils/getFunctions';
import Style from 'pages/project-onboarding/style';
import { useRecoilState } from 'recoil';
import { OrderPayload } from 'pages/project-onboarding/store';
import MemberAccessTable from 'pages/project-onboarding/components/preview/member-access-table';

const MemberAccess = () => {
    const [order] = useRecoilState(OrderPayload);

    const roleData = {
        projectAdmin: order?.adminAccessMemberList || [],
        fs: order?.fortifyMembers || [],
        sca: order?.snykPreviewMembers || [],
        testPlan: order?.testPlanUserList || [],
        sonarQube: order?.sonarQubeUserList || [],
        artifactory: order?.artifactoryUserList || [],
    };

    if (getMemberAccessForPreview(roleData)?.length === 0) return null;
    return (
        <Box sx={Style?.preview?.wrapper}>
            <Box sx={Style?.preview?.heading}>Member Access for ADO / DevOps Project On-boarding</Box>
            <Divider variant="horizontal" sx={Style?.preview?.divder} />
            {getMemberAccessForPreview(roleData)?.map((member, index) => (
                <MemberAccessTable member={member} index={index} members={roleData} />
            ))}
        </Box>
    );
};

export default MemberAccess;
