import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import { CssTextField } from 'components/select';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';

const HCode = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);

    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [helperText, setHelperText] = React.useState(false);

    const setHCode = (hCode) => {
        setOrder({
            ...order,
            hCode,
        });
    };

    React.useEffect(() => {
        if (order?.hCode && order?.hCode !== null && order?.hCode !== '' && order?.hCode !== undefined && !/^[-a-zA-Z0-9]{1,64}$/.test(order?.hCode)) {
            setHelperText(true);
            setValidForm({ ...validForm, isHCodeInvalid: true });
        } else {
            setHelperText(false);
            setValidForm({ ...validForm, isHCodeInvalid: false });
        }
    }, [order?.hCode]);

    const validateInput = () => {
        if ((isTouched && order?.isTouched && !order?.hCode) || helperText) {
            return 'validate';
        }
        return '';
    };

    React.useEffect(() => {
        if (!order?.hCode && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.hCode, order?.isTouched]);

    return (
        <>
            <Box display="flex" alignItems="center" gap={1}>
                <CssTextField
                    size="small"
                    sx={{
                        width: (theme) => theme.spacing('100%'),
                        '& .MuiFormHelperText-root': {
                            marginTop: (theme) => theme.spacing('-2'),
                        },
                    }}
                    autoComplete="off"
                    label="Budget Code (H-code, Cross Charge)"
                    isRequired
                    error={helperText}
                    helperText={helperText && <span>Budget Code must be 1 to 64 characters long and can include letters, numbers, and hyphens only.</span>}
                    value={order?.hCode || ''}
                    onChange={(e) => setHCode(e.target.value)}
                    className={validateInput()}
                />
            </Box>
        </>
    );
};

export default React.memo(HCode);
