import React from 'react';
import { Box, CircularProgress, Typography, Grid } from '@mui/material';
import { CssTextField } from 'components/select';
import DoneIcon from '@mui/icons-material/Done';
import InfoTooltip from 'pages/project-onboarding/components/info-tooltip';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import { OrderPayload, validOnboardingForm, isValidationLoading } from 'pages/project-onboarding/store';
import ProjectOnboardingStyles from 'pages/project-onboarding/style';

export const SASTVersion = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [validForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [, setIsLoading] = useRecoilState(isValidationLoading);
    const [helperText, setHelperText] = React.useState(false);

    const validateSASTApplicationVersion = useMutation(() =>
        axios.get(
            `${endPoints.day2Operation.ProjectOnboarding?.Jwt?.sastApplicationVersionValidate}?projectName=${order?.sastApplicationName}&projectVersion=${order?.sastVersion}`
        )
    );

    const setVersion = (sastVersion = null) => {
        setOrder({
            ...order,
            sastVersion,
        });
    };

    React.useEffect(() => {
        if (
            order?.sastVersion &&
            order?.sastVersion !== null &&
            order?.sastVersion !== '' &&
            order?.sastVersion !== undefined &&
            (order?.sastVersion.length < 3 || !/^[0-9a-zA-Z]+(\.[0-9a-zA-Z]+)*$/.test(order?.sastVersion))
        ) {
            setHelperText(true);
            setValidForm({ ...validForm, isInvalidSASTApplicationVersion: true });
        } else {
            setHelperText(false);
            setValidForm({ ...validForm, isInvalidSASTApplicationVersion: false });
        }
    }, [order?.sastVersion]);

    const validateInput = () => {
        if (
            (isTouched && order?.isTouched && !order?.sastVersion) ||
            helperText ||
            (validForm?.isInvalidSASTApplicationVersion &&
                validForm?.isInvalidSASTApplicationVersion &&
                (validateSASTApplicationVersion?.data?.messages?.length > 0 || validateSASTApplicationVersion?.error?.length > 0))
        ) {
            return 'validate';
        }
        return '';
    };

    const getHelperText = () => {
        if (helperText) {
            return <span>SAST Version must have min 3 digits and can contain dot(.) as special chars.</span>;
        }
        if (
            validForm?.isInvalidSASTApplicationVersion &&
            validForm?.isInvalidSASTApplicationVersion &&
            (validateSASTApplicationVersion?.data?.messages?.length > 0 || validateSASTApplicationVersion?.error?.length > 0)
        ) {
            return (
                <Box sx={ProjectOnboardingStyles.error}>
                    {validateSASTApplicationVersion?.data?.messages?.[0] || validateSASTApplicationVersion?.error?.[0]}
                </Box>
            );
        }
        if (validateSASTApplicationVersion?.isLoading) {
            return 'Checking SAST application version availability';
        }
        return '';
    };

    const getVersionValid = () => {
        if (validateSASTApplicationVersion?.isLoading) {
            return <CircularProgress color="inherit" size={14} />;
        }
        if (order?.sastVersion && !helperText && !validForm?.isInvalidSASTApplicationVersion) {
            return !validateSASTApplicationVersion?.isLoading && validateSASTApplicationVersion?.isSuccess ? <DoneIcon sx={{ color: '#00A86B' }} /> : null;
        }
        return false;
    };
    React.useEffect(() => {
        if (validateSASTApplicationVersion?.isSuccess && validateSASTApplicationVersion?.data?.data) {
            setValidForm({ ...validForm, isInvalidSASTApplicationVersion: true });
        }
        if (validateSASTApplicationVersion?.isSuccess && !validateSASTApplicationVersion?.data?.data) {
            setValidForm({ ...validForm, isInvalidSASTApplicationVersion: false });
        }
    }, [validateSASTApplicationVersion?.isSuccess]);

    React.useEffect(() => {
        if (order?.sastVersion && order?.sastVersion?.trim()?.length > 2 && order?.fortifyOption === 'CreateNewVersionInExistingApplication') {
            validateSASTApplicationVersion.mutate();
        }
    }, [order?.sastVersion]);

    React.useEffect(() => {
        setIsLoading(validateSASTApplicationVersion?.isLoading);
    }, [validateSASTApplicationVersion?.isLoading]);

    React.useEffect(() => {
        if (!order?.sastVersion && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.sastVersion, order?.isTouched]);

    React.useEffect(() => {
        if (order?.fortifyOption === 'CreateNewApplication') {
            setVersion('1.0');
            setHelperText(false);
        }
    }, [order?.fortifyOption]);

    return (
        <Box display="flex" alignItems="flex-start" flexDirection="row" data-testid="service-account-text" minHeight={62} gap={1}>
            <CssTextField
                data-testid="app-name-text"
                size="small"
                sx={{
                    width: (theme) => theme.spacing('100%'),
                    '& .MuiFormHelperText-root': {
                        marginTop: (theme) => theme.spacing('-2'),
                    },
                }}
                autoComplete="off"
                disabled={!order?.sastApplicationName && order?.fortifyOption === 'CreateNewVersionInExistingApplication'}
                label="SAST Version *"
                error={helperText}
                helperText={getHelperText()}
                value={order?.sastVersion || null}
                onChange={(e) => setVersion(e.target.value)}
                className={validateInput()}
                InputProps={{
                    endAdornment: <>{order?.fortifyOption === 'CreateNewVersionInExistingApplication' && getVersionValid()}</>,
                }}
            />{' '}
            <InfoTooltip
                maxWidth="300px"
                title={
                    <>
                        <Box sx={ProjectOnboardingStyles.helpImgStyle.wrapper}>
                            <Box sx={ProjectOnboardingStyles.helpImgStyle.namingRuleWrapper}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sx={ProjectOnboardingStyles.helpImgStyle.namingRule}>
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.emptyLine} />
                                        <Typography sx={ProjectOnboardingStyles.helpImgStyle.typographyStyle} marginBottom="4px">
                                            SAST Version must have min 3 digits and can contain dot(.) as special chars. e.g. 1.0
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                    </>
                }
            />
        </Box>
    );
};

export default React.memo(SASTVersion);
