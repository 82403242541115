import React from 'react';
import { Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import axios, { endPoints } from 'api/request';
import { useMutation } from 'react-query';
import Select from 'components/select';
import { OrderPayload, validOnboardingForm } from 'pages/project-onboarding/store';

export const SASTVersionSelect = () => {
    const [isTouched, setIsTouched] = React.useState(false);
    const [isValidForm, setValidForm] = useRecoilState(validOnboardingForm);
    const [order, setOrder] = useRecoilState(OrderPayload);
    const [options, setOptions] = React.useState([]);

    const sastVersionApi = useMutation(() =>
        axios.get(`${endPoints.day2Operation.ProjectOnboarding.Jwt.sastVersion}/versions?projectName=${order?.sastApplicationName}`)
    );

    const setApplicationService = (sastVersion) => {
        setOrder({
            ...order,
            sastVersion,
        });
        setValidForm({ ...isValidForm, isDuplicateSastApplication: false });
    };

    const resetOptions = () => {
        setOptions([]);
    };

    const isExistsInOptions = (sastVersion) => options.find((option) => sastVersion === option.value);

    const handleOnSelectChange = (selected) => setApplicationService(selected.value);

    React.useEffect(() => {
        if (sastVersionApi?.isSuccess) {
            setOptions(sastVersionApi?.data?.data?.map((app) => ({ label: app, value: app })));
        }
    }, [sastVersionApi?.isSuccess]);

    React.useEffect(() => {
        if (options && options?.length === 1 && !order?.sastVersion) {
            setOrder({ ...order, sastVersion: options?.[0].value });
        }
        if (options && options?.length && order?.sastVersion) {
            const sastVersion = isExistsInOptions(order?.sastVersion);
            setOrder({ ...order, sastVersion: sastVersion?.value });
        }
    }, [options]);

    React.useEffect(() => {
        resetOptions();
        if (order?.sastApplicationName !== null) sastVersionApi.mutate();
    }, [order?.sastApplicationName]);

    React.useEffect(() => {
        if (!order?.sastVersion && order?.isTouched) setIsTouched(true);
        else setIsTouched(false);
    }, [order?.sastVersion, order?.isTouched]);

    return (
        <Box display="flex" alignItems="center" gap={1}>
            <Select
                key="SastVersion"
                label="SAST Version *"
                value={order?.sastVersion || null}
                options={options}
                handleOnSelect={(value) => handleOnSelectChange(value)}
                disabled={sastVersionApi.isLoading || !order?.sastApplicationName}
                isLoading={sastVersionApi.isLoading}
                isRequired={isTouched}
            />
        </Box>
    );
};

export default React.memo(SASTVersionSelect);
